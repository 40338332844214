import React, { useRef, useState, useEffect } from 'react'
import { Canvas, useFrame, useLoader, useThree } from '@react-three/fiber'
import { TextureLoader } from 'three'
import { Environment, OrbitControls, Html, Sky, SoftShadows } from "@react-three/drei";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { Suspense } from "react";
import JEASINGS from 'https://esm.sh/jeasings'


import './App.css';

const annotations = [
  {
    title: 'Floor 1',
    camPos: {
      x: -3,
      y: 1,
      z: 0,
    },
    lookAt: {
      x: -5,
      y: 1,
      z: 8,
    },
  },
  {
    title: 'Floor 2',
    // description: '<p>Bathroom Sink is good for washing your hands</p>',
    camPos: {
      x: -5,
      y: 2,
      z: -2,
    },
    lookAt: {
      x: -5,
      y: 2,
      z: 8,
    },
  },
  {
    title: 'Floor 3',
    camPos: {
      x: -3,
      y: 1,
      z: 0,
    },
    lookAt: {
      x: -5,
      y: 3,
      z: 8,
    },
  }
]

function Model() {
  const gltf = useLoader(GLTFLoader, '/maninder.glb')
  gltf.scene.traverse( function( node ) {
      if ( node.isMesh ) { 
        node.castShadow = true; 
        node.receiveShadow = true; 
      }
  } );
  return (
    <primitive object={gltf.scene}  scale={0.07} position={[-20, 0, 10]}/>
  )
}
function Annotations({ controls }) {
  const { camera } = useThree()
  const [selected, setSelected] = useState(-1)
  return (
    <>
      {annotations.map((a, i) => {
        return (
          <Html key={i} position={[a.lookAt.x, a.lookAt.y, a.lookAt.z]} className={ i === selected ? 'floor-pill active' : 'floor-pill' }>
            <label
              style={{ cursor: 'pointer' }}
              onPointerUp={() => {
                setSelected(i)
                console.log( "state.camera", controls );

                new JEASINGS.JEasing(controls.current.target)
                .to(
                    {
                        x: a.lookAt.x,
                        y: a.lookAt.y,
                        z: a.lookAt.z,
                    },
                    1000
                )
                .easing(JEASINGS.Cubic.Out)
                .start()

                // change camera position
                new JEASINGS.JEasing(camera.position)
                .to(
                    {
                        x: a.camPos.x,
                        y: a.camPos.y,
                        z: a.camPos.z,
                    },
                    1000
                )
                .easing(JEASINGS.Cubic.Out)
                .start()
              }}
            >
              {a.title}
            </label>
            {a.description && i === selected && (
              <div
                id={'desc_' + i}
                className="annotationDescription"
                dangerouslySetInnerHTML={{ __html: a.description }}
              />
            )}
          </Html>
        )
      })}
    </>
  )
}

function JEasings() {
  useFrame(() => {
      JEASINGS.update()
  })
}
//Presets: apartment, city, dawn, forest, lobby, night, park, studio, sunset, warehouse
export default function App() {
  const ref = useRef()
  return (
    <div className='canvas-root'>
      <Canvas shadows  camera={{ position: [5, 2, 10], fov: 50 }}>
        <OrbitControls ref={ref}/>
        <Sky
            sunPosition={[50, 10, 0]} inclination={0} rayleigh={0.1} turbidity={0.1} mieCoefficient={0.1} mieDirectionalG={0.8}
         />
        {/* <SoftShadows /> */}
        <ambientLight intensity={1} color="white"/>
        <pointLight castShadow position={[0, 10, 0]} color="grey" intensity={5000}/>
        <directionalLight castShadow position={[0, 10, 0]} intensity={500} shadow-mapSize={1024} color="white">
          <orthographicCamera attach="shadow-camera" args={[-10, 10, 10, -10, 0.1, 50]} />
        </directionalLight>
        <Suspense fallback={null}>
          <Model />
          {/* <mesh position={[0, 10, 0]} visible>
            <sphereGeometry args={[1, 16, 16]} color="yellow"/>
            <meshStandardMaterial color="yellow" transparent />
          </mesh> */}
          <JEasings />
        </Suspense>
        <Annotations controls={ref} />
      </Canvas>
    </div>
  )
}