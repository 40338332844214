import React, { useRef, useState, useEffect } from 'react'
import { Canvas, useFrame, useLoader, useThree } from '@react-three/fiber'
import { TextureLoader } from 'three'
import { Environment, OrbitControls, Html, Sky, SoftShadows } from "@react-three/drei";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { Suspense } from "react";
import JEASINGS from 'https://esm.sh/jeasings'
import * as THREE from 'three'


import './App.css';

const annotations = [
  {
    title: 'Floor Offices',
    description: '<p>Enjoy a dedicated office floor with your own <br> private amenities such as a branded reception, <br> meeting rooms, executive offices and more.</p>',
    camPos: {
      x: -10,
      y: -2,
      z: 5,
    },
    lookAt: {
      x:  -1,
      y: -4,
      z: 0,
    },
  },
  {
    title: 'Pool Area',
    camPos: {
      x: 2,
      y: 0,
      z: -2,
    },
    lookAt: {
      x: -2,
      y: -1,
      z: 0,
    },
  },
  {
    title: 'Tower 1',
    camPos: {
      x: -2,
      y: 2,
      z: 5,
    },
    lookAt: {
      x: 0,
      y: 3,
      z: -5,
    },
  },
  {
    title: 'Tower 2',
    camPos: {
      x: -5,
      y: 4,
      z: 5,
    },
    lookAt: {
      x: 4,
      y: 3.5,
      z: 3,
    },
  },
  {
    title: 'Balcony',
    description: '<p>As compared to a regular window, <br>a huge balcony will help allow a large <br>amount of light into your living space.</p>',
    camPos: {
      x: -4.8,
      y: 8.7,
      z: -3,
    },
    lookAt: {
      x: -4,
      y: 8,
      z: -4,
    },
  },
  {
    title: 'Roof Area',
    description: '<p>Roof mounted evaporative air coolers for <br>efficient cooling.</p>',
    camPos: {
      x: -1,
      y: 12,
      z: -1,
    },
    lookAt: {
      x: 0,
      y: 11,
      z: -3,
    },
  }
]

function Model() {
  const gltf = useLoader(GLTFLoader, '/scene/scene.gltf')
  gltf.scene.traverse( function( node ) {
      if ( node.isMesh ) { 
        node.castShadow = true; 
        node.receiveShadow = true; 
      }
  } );
  return (
    <primitive object={gltf.scene}  scale={0.001} position={[0, -4, 0]}/>
  )
}

function Annotations({ controls }) {
  const { camera } = useThree()
  const [selected, setSelected] = useState(-1)
  return (
    <>
      {annotations.map((a, i) => {
        return (
          <Html key={i} position={[a.lookAt.x, a.lookAt.y, a.lookAt.z]} className={ i === selected ? 'floor-pill active' : 'floor-pill' }>
            <label
              style={{ cursor: 'pointer' }}
              onPointerUp={() => {
                setSelected(i)
                console.log( 'camera', controls )

                controls.current.enableZoom = false;
                controls.current.minDistance = 1;
                //Change camera zoom and disable it untill not enabled

                //Change camera view
                new JEASINGS.JEasing(controls.current.target)
                .to(
                    {
                        x: a.lookAt.x,
                        y: a.lookAt.y,
                        z: a.lookAt.z,

                    },
                    1000
                )
                .easing(JEASINGS.Cubic.Out)
                .start()

                // change camera position
                new JEASINGS.JEasing(camera.position)
                .to(
                    {
                        x: a.camPos.x,
                        y: a.camPos.y,
                        z: a.camPos.z,
                    },
                    1000
                )
                .easing(JEASINGS.Cubic.Out)
                .start()
              }}
            >
              {i+1}
            </label>
            {i === selected && (
              <div
                id={'desc_' + i}
                className="annotationDescription"
              >
                <h3>{a.title}</h3>
                <div dangerouslySetInnerHTML={{ __html: a.description }}></div>
                <span className='scene-cam-reset'
                  style={{ cursor: 'pointer' }}
                  onPointerUp={(elem) => {
                    setSelected(-1);
                    controls.current.enableZoom = true;
                    controls.current.minDistance = 8;
                    new JEASINGS.JEasing(controls.current.target)
                    .to(
                        {
                            x: 0,
                            y: 0,
                            z: 0,
                        },
                        1000
                    )
                    .easing(JEASINGS.Cubic.Out)
                    .start()
        
                    // change camera position
                    new JEASINGS.JEasing(camera.position)
                    .to(
                        {
                          x: -5,
                          y: 2,
                          z: 15,
                        },
                        1000
                    )
                    .easing(JEASINGS.Cubic.Out)
                    .start()
                  }}
                >
                  x
                </span>
              </div>
            )}
          </Html>
        )
      })}
    </>
  )
}

function JEasings() {
  useFrame(() => {
      JEASINGS.update()
  })
}
//Presets: apartment, city, dawn, forest, lobby, night, park, studio, sunset, warehouse
export default function AppScrapper() {
  const ref = useRef()
  const floorColorMap = useLoader(TextureLoader, '/textures/grassmap.jpg');

  floorColorMap.wrapS = THREE.RepeatWrapping;
  floorColorMap.wrapT = THREE.RepeatWrapping;
  floorColorMap.repeat.set( 50, 50 );


  const treesModel = useLoader(GLTFLoader, '/models/tree_animate/scene.gltf');
  treesModel.scene.traverse( function( node ) {
    if ( node.isMesh ) { 
      node.castShadow = true; 
      node.receiveShadow = true; 
    }
  } );
  return (
    <div className='canvas-root'>
      <Canvas shadows camera={{ position: [-5, 2, 15], fov: 100 }}>
        <OrbitControls ref={ref} maxDistance={17} minDistance={8} enableZoom={true} />
        {/* <Sky
          sunPosition={[50, 10, 0]} inclination={0} rayleigh={0.1} turbidity={0.1} mieCoefficient={0.02} mieDirectionalG={0.8}
        /> */}
        <Environment files="/environment/overcast_soil_puresky_4k.hdr" background />
        <ambientLight intensity={0} color="#fffddf"/>
        <pointLight castShadow position={[50, 10, 0]} color="#fff6c6" intensity={2}/>
        <directionalLight castShadow position={[50, 10, 0]} intensity={1} shadow-mapSize={1024} color="#fff6c6">
          <orthographicCamera attach="shadow-camera" args={[-10, 10, 10, -10, 0.1, 50]} />
        </directionalLight>

        
        
        <mesh visible castShadow receiveShadow  position={[0, -4.1, 0]} rotation={[80.15, 73.82, 0.05]}>
          <boxGeometry args={[0.2, 200, 200]}></boxGeometry>
          <meshStandardMaterial map={floorColorMap} />
        </mesh>
        <Suspense fallback={null}>
          <Model />
          <primitive object={treesModel.scene} scale={0.005} rotation={[0,90,0]} position={[0, -4, 7]} />
          <JEasings />
        </Suspense>
        <Annotations controls={ref} />
      </Canvas>
    </div>
  )
}